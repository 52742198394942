/*globals $*/
'use strict';

import lozad from 'lozad'
const getSvg = () => import("./external/functions/svg");
const getOwlCarousel = () => import("./external/functions/owl_carousel");
const getHeaderFunctions = () => import("./external/functions/header");
const getSuffle = () => import("./external/functions/shuffle");
import Dropzone from 'dropzone';

/* DETECT MOBILE */
var isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true;
}

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 100; //$('header').outerHeight();

var playing1 = false;
Dropzone.autoDiscover = false;

$( document ).ready(function() {

    //LOAD NAVBAR IF NEEDED
    if($("#nav-open-close").length > 0) {
        getHeaderFunctions().then(headerModule => {
            headerModule.showNavBar();
        });
    }

    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-home').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselHome();
        });
    }

    //LOAD SVG IF NEEDED
    if($(".svg").length > 0) {
        getSvg().then(svgModule => {
            svgModule.prepareSvg();
        });
    }

    //intervalo de tiempo para calcular constantemente posicion del scroll
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    $('.close_contact_button').click(function() {
        $('#contact-desplegable').removeClass('open');
    });

    $('.image-box').each(function(){ calcularPosicion( $(this) ); });

    $('.member-open').click(function() {
        $('#member-info').css('display', 'block');
    });

    $('#member-close').click(function() {
        $('#member-info').css('display', 'none');
    });

    $('#member-section-close').click(function() {
        $('#member-info').css('display', 'none');
    });


    /* ACCORDION EFFECT GENERAL */

    $(".accordion .accordion-head").click(function() {
        var link = $(this);
        var closest_ul = link.closest("ul");
        var parallel_active_links = closest_ul.find(".active")
        var closest_li = link.closest("li");
        var link_status = closest_li.hasClass("active");
        var count = 0;

        closest_ul.find(".accordion-body").slideUp(function() {
            if (++count == closest_ul.find(".accordion-body").length)
                    parallel_active_links.removeClass("active");
        });

        if (!link_status) {
            closest_li.children(".accordion-body").slideDown();
            closest_li.addClass("active");
        }
    });

    $('#select-subject').change(function() {
        if ($(this).val() === 'enviar-cv') {
            console.log('asdf');
            $('#inputs-cv').slideDown();
        } else {
            $('#inputs-cv').slideUp();
        }
    });



    $('#changeLang').on('click', function (){
        var value = $(this).attr('data-href');
        $('#modal_edit_exit').attr('href', value);
    });


    $('#removeImg').on('click', function(){
        var src =  $('#image_holder').val();
        $('#thumbnail').attr('src', src);
        $('#image_featured').attr('value', src);
    });

    $('.openMedia').on('click', function(){
        $('#media-info').css('display', 'block');
    });

    $('#media-section-close').on('click', function() {
        $('#media-info').css('display', 'none');
    });

    $('#btn_media-section-close').on('click', function() {
        $('#media-info').css('display', 'none');
    });

    $('.openFileMedia').on('click', function(){
        $('#file-media-info').css('display', 'block');
    });

    $('#file-media-section-close').on('click', function() {
        $('#file-media-info').css('display', 'none');
    });

    $('#file_media-section-close').on('click', function() {
        $('#file-media-info').css('display', 'none');
    });

    $('[id^="img-"]').on('click', function(){
        $('[id^="img-"]').removeClass('img-active');
        $(this).addClass('img-active');
        $('#featured_image').val($(this).attr('data-url'));
        $('#image_featured').val($(this).attr('data-url'));
        $('#thumbnail').attr('src', $(this).attr('data-url'));

    });

    $('#selectFromGallery').on('click', function(){
        if ($('#featured_image').val() !== '0'){
            $('#media-info').css('display', 'none');
        }
    });

    $('#uploadImage').on('click', function(){
        $('#media-info').css('display', 'none');
    });

    $('input[name=category]').on('change', function(){
        var value = $('input[name=category]:checked').val();

        if($('#image_featured').length > 0) {
            var img = $('#image_featured').val();

            if (img.includes('image-holder')) {
                switch (value) {
                    case '0':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        break;
                    case '5':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_cambra.png");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_cambra.png");
                        break;
                    case '6':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        break;
                    case '7':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_projects.png");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_projects.png");
                        break;
                    case '8':
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_others.png");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder_others.png");
                        break;
                    default:
                        $("#thumbnail").attr("src", "http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        $('#image_featured').val("http://hundreds-wordpress-uploads.s3.eu-west-3.amazonaws.com/ccoc/uploads/2021/08/image-holder.jpg");
                        break;
                }
            }
        }
    });

    if($('#uploadMedia').length) {
        var img = new Dropzone('#uploadMedia', {
            acceptedFiles: 'image/jpeg,image/png',
            paramName: 'upload_file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader').css('display', 'none');
                $('#image_featured').val(JSON.parse(response));
                $('#thumbnail').attr('src', JSON.parse(response));
                $('#media-info').css('display', 'none');
            },
            error: function (file, error, xhr) {
                $('#excel-loader').css('display', 'none');
                $('#error-upload').css('display', 'block');
            }
        });
    }

    if($('#uploadFileMedia').length) {
        var img = new Dropzone('#uploadFileMedia', {
            acceptedFiles: 'application/pdf',
            paramName: 'upload_file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader').css('display', 'none');
                $('#file').val(JSON.parse(response));
                $('#upload_file').val(JSON.parse(response));
                $('#accept').css('display', 'block');
                $('#media-info').css('display', 'none');
            },
            error: function (file, error, xhr) {
                $('#excel-loader').css('display', 'none');
                $('#error-upload').css('display', 'block');
            }
        });
    }

    $('.post_fields').css('display', 'none');

    $('[name="have_post"]').change(function() {

        if($(this).val() == 1){
            $('.post_fields').css('display', 'block');

        }else{
            $('.post_fields').css('display', 'none');
        }
    });

    $('[name="responsible"]').change(function(){
        if($(this).val() == 1){
            $('[name="visibility"]').prop('disabled', true)
            $('[name="visibility"]').filter('[value=private]').prop('checked', true);
        }else{
            $('[name="visibility"]').prop('disabled', false)
        }
    })

    var timeout = null;

    $('#event_title').keydown(function() {
        clearTimeout(timeout);

        timeout = setTimeout(function() {

            if($('#event_slug').val() == '') {
                var slug = convertToSlug($('#event_title').val());

                $.ajax({
                    type: "GET",
                    url: '/eventos/check/slug/' + slug,
                    async: false,
                    success: function(data) {
                        var resp = JSON.parse(data);

                        if( resp == 'ok'){
                            $('#event_slug').val(slug);
                        }else{
                            $('#event_slug').val(slug + '-' + resp);
                        }
                    },
                });
            }
        }, 1500);
    });

    $('#users-table').DataTable({
        destroy: true,
        ordering: true,
        bInfo : false,
        responsive: true,
        scrollX: true,
        language: {
            "lengthMenu": "Mostra _MENU_ entrades",
            "zeroRecords": "No hi ha resultats",
            "info": "Showing page _PAGE_ of _PAGES_",
            "infoEmpty": "No records available",
            "infoFiltered": "(filtered from _MAX_ total records)",
            "search": '',
            "searchPlaceholder": "Buscador",
            "paginate": {
                "previous": "Anterior",
                "next": "Següent"
            }
        },
    });

    $('#billing-table').DataTable({
        destroy: true,
        ordering: false,
        searching: false,
        bInfo : false,
        responsive: true,
        scrollX: true,
        language: {
            "lengthMenu": "Mostra _MENU_ entrades",
            "zeroRecords": "No hi ha resultats",
            "info": "Showing page _PAGE_ of _PAGES_",
            "infoEmpty": "No records available",
            "infoFiltered": "(filtered from _MAX_ total records)",
            "search": '',
            "searchPlaceholder": "Buscador",
            "paginate": {
                "previous": "Anterior",
                "next": "Següent"
            }
        },
    });

    $('#my-tenders-table').DataTable({
        destroy: true,
        ordering: true,
        bInfo : false,
        responsive: true,
        scrollX: true,
        language: {
            "lengthMenu": "Mostra _MENU_ entrades",
            "zeroRecords": "No hi ha resultats",
            "info": "Showing page _PAGE_ of _PAGES_",
            "infoEmpty": "No records available",
            "infoFiltered": "(filtered from _MAX_ total records)",
            "search": '',
            "searchPlaceholder": "Buscador",
            "paginate": {
                "previous": "Anterior",
                "next": "Següent"
            }
        },
        columnDefs: [ {
            targets: 0,
            orderable: false
        } ]
    });

    $('#tenders-dashboard-table').DataTable({
        destroy: true,
        order: [[ 1, "asc" ]],
        bFilter: false,
        searching: false,
        lengthChange: false,
        bInfo : false,
        responsive: true,
        scrollX: true,
        language: {
            "lengthMenu": "Mostra _MENU_ entrades",
            "zeroRecords": "No hi ha resultats",
            "info": "Showing page _PAGE_ of _PAGES_",
            "infoEmpty": "No records available",
            "infoFiltered": "(filtered from _MAX_ total records)",
            "search": '',
            "searchPlaceholder": "Buscador",
            "paginate": {
                "previous": "Anterior",
                "next": "Següent"
            }
        },
        columnDefs: [ {
            targets: 0,
            orderable: false
        } ]
    });

    $('[id^="deleteOtherAddress_"]').on('click', function (){
        $('#address-item-' + $(this).attr('data-id')).remove();
        var value = $('#count_other_address').val();
        $('#count_other_address').val( value - 1);
    });

    $('#other-user_section').css('display', 'none');

    $('#addOtherUser').on('click', function (){
        $('#other-user_section').css('display', 'block');
    })

    $('#generate_password').on('click', function(){
        $('#password').val(randString(12));
    });

    $('[id^="showModal_"]').on('click', function (){
        var id =  $(this).attr('data-id');
        $('#modal_dashboard_' +  id).modal('toggle');

        var url = $('#udate_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'tender'},
            async: false,
            success: function(data) {
                data = JSON.parse(data);
                $('#showModal_' +  id).css('display', 'none');
            },
        });
    });

    $('[id^="file_download"]').on('click', function (){
        var id =  $(this).attr('data-id');
        var url = $('#udate_notifications').val();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type: "POST",
            url: url,
            data: {id:id, type:'file'},
            async: false,
            success: function(data) {
                data = JSON.parse(data);
                $('#showModal_' +  id).css('display', 'none');
            },
        });
    })
});

$(window).scroll(function(){
     didScroll = true;

     $('.image-box').each(function(){ calcularPosicion( $(this) ); });

});
$(window).resize(function(){ });

function randString(length){
    var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
        var i = Math.floor(Math.random() * chars.length);
        pass += chars.charAt(i);
    }
    return pass;
}

function hasScrolled() {
    var st = $(window).scrollTop();

    if (st > navbarHeight){
        $('.header').addClass('fixed');
    } else {
        $('.header').removeClass('fixed');
    }
}

function calcularPosicion(element) {
    var alto = $(window).height() - 10;
    var wScroll = $(window).scrollTop();

    if(element.length && (element.offset().top - alto) < wScroll ){
        element.addClass('show');
    }
}

function closeMenu() {
    $('#header').removeClass('menu-opened');
    $('#nav-open-close').removeClass('open');
    $('#menu-desplegable').removeClass('open');
}

//Lazy load of all the images
document.addEventListener('DOMContentLoaded', function(){
    if($(".lozad").length > 0) {
        const observer = lozad('.lozad', {
          threshold: 0.1,
          load: function(el) {
            if(el.dataset.src){ el.src = el.dataset.src; }
            if(el.dataset.backgroundImage){ el.style.backgroundImage = "url('"+el.dataset.backgroundImage+"')"; }
          },
        });
        observer.observe();
    }

    //LOAD SHUFFLE IF NEEDED
    if($(".shuffleNews").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleNews();
        });
    }

    if($(".shuffleLegislations").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleLegislations();
        });
    }

    if($(".shuffleFiles").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleFiles();
        });
    }

    if($(".shuffleEvents").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleEvents();
        });
    }

    if($(".shuffleCommisions").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleCommisions();
        });
    }

    if($(".shuffleCirculars").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleCirculars();
        });
    }

    if($(".shuffleWord").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleWord();
        });
    }

    if($(".shuffleProjects").length > 0) {
        getSuffle().then(suffleModule => {
            suffleModule.startShuffleProjects();
        });
    }
});


function closeAllTenderFilters() {
    $('[id^="tenders-filter-full"]').slideUp();
    $('[id^="button-tenders"]').removeClass('selected');
    $('[id^="button-search-tenders"]').removeClass('selected');
}

